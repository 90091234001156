import { useSnackbar } from "notistack";
import { useCallback } from "react";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_USE_PROD
  ? process.env.REACT_APP_BACKEND_URL_PROD
  : process.env.REACT_APP_BACKEND_URL;

interface ApiRequestOptions {
  method: string;
  endpoint: string;
  body?: any;
  onSuccess?: (data: any) => void;
  onError?: (message: string) => void;
  hideErrorSnackbar?: boolean;
  hideSuccessSnackbar?: boolean;
}

export const apiRequest = async (endpoint: string, options: RequestInit) => {
  const response = await fetch(`${BACKEND_URL}${endpoint}`, options);
  return response;
};

interface SnackbarOptions {
  variant: "success" | "error";
  anchorOrigin: {
    vertical: "bottom";
    horizontal: "center";
  };
  autoHideDuration: number;
}

const getOptions = (
  response: Response | null,
  closeSnackbar: (key: any) => void
): SnackbarOptions => ({
  variant: response?.ok ? "success" : "error",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  autoHideDuration: 2500,
});

export const useApiRequest = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const makeApiRequest = useCallback(
    async ({
      method,
      endpoint,
      body,
      onSuccess,
      onError,
      hideErrorSnackbar,
      hideSuccessSnackbar,
    }: ApiRequestOptions) => {
      try {
        const response = await apiRequest(endpoint, {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const parsedResponse = await response.json();

        if (response.ok) {
          if (method !== "GET" && !hideSuccessSnackbar) {
            enqueueSnackbar(
              "Request succeeded!",
              getOptions(response, closeSnackbar)
            );
          }
          if (onSuccess) {
            return onSuccess(parsedResponse.result);
          }
        } else {
          const message = `Request failed: ${parsedResponse.error}`;
          console.error(message);
          if (onError) {
            onError(message);
          }
          if (!hideErrorSnackbar) {
            enqueueSnackbar(message, getOptions(response, closeSnackbar));
          }
        }
      } catch (error) {
        const message = `Request error: ${error}`;
        console.error(message);
        enqueueSnackbar(message, getOptions(null, closeSnackbar));
        throw error;
      }
    },
    []
  );

  return makeApiRequest;
};
