import React, { useState } from "react";
import styled from "styled-components";
import { useApiRequest } from "../network";
import { useStore } from "../store";
import { StyledButton, ButtonContainer } from "../text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  color: #333;
`;

const Message = styled.p`
  margin-bottom: 30px;
  max-width: 600px;
  line-height: 1.6;
  color: #666;
`;

const StatusMessage = styled.p`
  margin-top: 20px;
  font-style: italic;
  color: #888;
`;

export default function WelcomePage() {
  const { authStatus, setAuthStatus } = useStore((state) => ({
    authStatus: state.authStatus,
    setAuthStatus: state.setAuthStatus,
  }));
  const makeApiRequest = useApiRequest();
  const user = authStatus?.user;
  const isWaitlisted = user?.waitlisted;
  const [clicked, setClicked] = useState(false);

  const handleJoinWaitlist = async () => {
    setClicked(true);
    await makeApiRequest({
      method: "POST",
      endpoint: "/user/waitlist",
      onSuccess: (authStatus) => {
        setClicked(false);
        setAuthStatus(authStatus);
      },
      onError: () => setClicked(false),
    });
  };

  return (
    <Container>
      <Title>Welcome to Climate Atlas</Title>
      {authStatus?.user ? (
        <>
          <Message>
            Thank you for your interest! Climate Atlas is currently in a limited
            access phase.
            {isWaitlisted
              ? " We'll notify you when access becomes available."
              : " Join our waitlist to be notified when access becomes available."}
          </Message>

          {!isWaitlisted && (
            <ButtonContainer>
              <StyledButton
                color={"blue"}
                onClick={handleJoinWaitlist}
                disabled={clicked}
              >
                Join Waitlist
              </StyledButton>
            </ButtonContainer>
          )}

          {isWaitlisted && (
            <StatusMessage>
              You're on the waitlist! We'll let you know when when access is
              granted.
            </StatusMessage>
          )}
        </>
      ) : (
        <Message>Please log in to continue</Message>
      )}
    </Container>
  );
}
