import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import { useApiRequest } from "../network";
import ComparisonPage from "../components/ComparisonPage";
import SidePanel from "../components/SidePanel";
import geojsonUrl from "../data/csas.geojson";
import geojsonCbsaUrl from "../data/cbsas.geojson";
import {
  GeographyLevel,
  useMetricMapping,
  useStore,
  TabValues,
} from "../store";
import Dive from "./Dive";
import { getCentroid } from "../utils";
import {
  getIdField,
  getIdFromExploreData,
  useFindFeature,
  useGeographyData,
} from "../hooks/useGeographyData";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

interface PanelProps {
  $isOpen: boolean;
}

const SidePanelContainer = styled.div<PanelProps>`
  width: 330px;
  transition: transform 0.3s ease-in-out;
`;

const BottomPanel = styled.div<PanelProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: ${(props) => (props.$isOpen ? "calc(100vh - 60px)" : "80vh")};
  width: calc(100% - 316px);
  background-color: white;
  transform: translateY(${(props) => (props.$isOpen ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MapContainer = styled.div<PanelProps>`
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
  width: calc(100% - 300px);
`;

export default function HomePage({
  initialTab = null,
}: {
  initialTab: string | null;
}) {
  const { level, id } = useParams<{ level: string; id: string }>();
  const {
    geojsonData,
    geojsonCbsaData,
    setGeojsonData,
    exploreData,
    setConfig,
    setGeojsonCbsaData,
    mapRef,
    activeTab,
    setActiveTab,
    setExploreData,
    setMetricConfig,
    setSidepanelData,
  } = useStore((state) => ({
    geojsonData: state.geojsonData,
    geojsonCbsaData: state.geojsonCbsaData,
    setGeojsonData: state.setGeojsonData,
    exploreData: state.exploreData,
    setConfig: state.setConfig,
    setGeojsonCbsaData: state.setGeojsonCbsaData,
    mapRef: state.mapRef,
    activeTab: state.activeTab,
    setActiveTab: state.setActiveTab,
    setExploreData: state.setExploreData,
    setMetricConfig: state.setMetricConfig,
    setSidepanelData: state.setSidepanelData,
  }));
  const { setMapping } = useMetricMapping();
  const [isBottomPanelOpen, setBottomPanelOpen] = useState(false);
  const geographyData = useGeographyData(level as GeographyLevel | null);
  const findFeature = useFindFeature();

  const makeApiRequest = useApiRequest();

  useEffect(() => {
    if (initialTab && TabValues.includes(initialTab)) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  useEffect(() => {
    console.log("Loading config");
    makeApiRequest({
      method: "GET",
      endpoint: "/tiered-config",
      onSuccess: setConfig,
      hideSuccessSnackbar: true,
    });
    makeApiRequest({
      method: "GET",
      endpoint: "/metric-config",
      onSuccess: setMetricConfig,
      hideSuccessSnackbar: true,
      // Already would error above anyway
      // and this can fail silently with the rest of the site working
      hideErrorSnackbar: true,
    });
    console.log("Metric config loaded");
  }, [setConfig, setMetricConfig, makeApiRequest]);

  useEffect(() => {
    console.log("Loading GeoJSON data");
    const loadGeoJsonData = async () => {
      try {
        const response = await fetch(geojsonUrl);
        const data = await response.json();
        setGeojsonData(data);
        setMapping(data.metrics);
        console.log("GeoJSON data loaded");
      } catch (error) {
        console.error("Failed to load GeoJSON data:", error);
      }
    };
    const loadCbsaGeoJsonData = async () => {
      try {
        const response = await fetch(geojsonCbsaUrl);
        const data = await response.json();
        setGeojsonCbsaData(data);
        // It's not gauranteed to be the same as the csa metric mapping
        setMapping(data.metrics);
        console.log("GeoJSON CBSA data loaded");
      } catch (error) {
        console.error("Failed to load GeoJSON CBSA data:", error);
      }
    };
    loadGeoJsonData();
    loadCbsaGeoJsonData();
  }, [setGeojsonData, setGeojsonCbsaData, setMapping]);

  useEffect(() => {
    console.log("Loading explore data");
    if (initialTab !== "Inspect") {
      return;
    }
    if (id && level) {
      const idField = getIdField(level as GeographyLevel | null);
      if (!idField || !geographyData || !["csa", "cbsa"].includes(level)) {
        return;
      }
      const properties = findFeature(id, level as GeographyLevel)?.properties;
      if (properties) {
        const data = {
          level: level as GeographyLevel,
          properties,
        };
        setExploreData(data);
        setSidepanelData(data);
      }
    }
  }, [
    id,
    initialTab,
    level,
    findFeature,
    geographyData,
    setExploreData,
    setActiveTab,
    setSidepanelData,
  ]);

  useEffect(() => {
    const openCode = getIdFromExploreData(exploreData);
    const shouldBeOpen =
      (activeTab === "Inspect" && !!openCode) || activeTab === "Compare";

    // Only update if the state actually needs to change
    setBottomPanelOpen((currentlyOpen) => {
      if (currentlyOpen !== shouldBeOpen) {
        return shouldBeOpen;
      }
      return currentlyOpen;
    });
    console.log("Setting bottom panel open", isBottomPanelOpen);

    // Only try to fly to location if we have a code
    if (openCode && mapRef) {
      const ourFeature = findFeature(openCode, exploreData?.level);
      if (ourFeature) {
        const center = getCentroid(ourFeature);
        if (center.every((coord) => coord !== 0 && !isNaN(coord))) {
          mapRef.flyTo({
            center,
            zoom: 6,
            speed: 0.8,
            curve: 1,
          });
        }
      }
    }
  }, [mapRef, exploreData, activeTab]);

  return (
    <PageContainer>
      <ContentContainer>
        <SidePanelContainer $isOpen={true}>
          <SidePanel />
        </SidePanelContainer>
        <MapContainer $isOpen={!isBottomPanelOpen}>
          <Map />
        </MapContainer>
      </ContentContainer>
      <BottomPanel $isOpen={isBottomPanelOpen}>
        {geojsonData &&
          geojsonCbsaData &&
          (activeTab === "Compare" ? <ComparisonPage /> : <Dive />)}
      </BottomPanel>
    </PageContainer>
  );
}
