import { useEffect, useRef } from "react";
import { create } from "zustand";
import mapboxgl from "mapbox-gl";
import { useNavigate } from "react-router-dom";

export type User = {
  nickname: string | null;
  email: string;
  waitlisted: boolean;
  admin?: boolean;
  whitelisted: boolean;
};

export type AuthStatus = {
  user: User | null;
};

export type Metric = string;

export interface Polygon {
  type: "Polygon";
  coordinates: number[][][];
}

export interface MultiPolygon {
  type: "MultiPolygon";
  coordinates: number[][][][];
}

export type Geometry = Polygon | MultiPolygon;

export interface CenterZoom {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

export interface CsaProperties {
  csaCode: string;
  cbsaCode: string;
  tractCode: string | null;
  name: string;
  scoreTiers: number[];
}

export interface GeojsonFeature {
  type: "Feature";
  properties: CsaProperties;
  geometry: Geometry;
}

export interface GeojsonData {
  type: "FeatureCollection";
  features: Array<GeojsonFeature>;
}

interface MetricConfig {
  datapoints: Record<string, string>;
  sources: Record<string, string>;
}

export type GeographyLevel = "csa" | "cbsa" | "tract";

export interface ExploreData {
  level: GeographyLevel;
  properties: CsaProperties;
}

interface _StoreState {
  geojsonData: GeojsonData | null;
  geojsonCbsaData: GeojsonData | null;
  geojsonTractData: Record<string, GeojsonFeature> | null;
  sidepanelData: ExploreData | null;
  domainTab: DomainTab;
  mapMetric: Metric;
  activeTab: Tab;
  exploreData: ExploreData | null;
  config: OverallScoringConfig | null;
  mapRef: mapboxgl.Map | null;
  selectedLevel: GeographyLevel;
  metricScores: {
    [key: string]: {
      scores: { [key: string]: number };
      loading: boolean;
    };
  };
  metricConfig: MetricConfig | null;
  getConsolidatedTractData: () => GeojsonData | null;
  authStatus: AuthStatus | null;
}

interface _StoreActions {
  setAuthStatus: (data: AuthStatus) => void;
  setGeojsonData: (data: GeojsonData) => void;
  setGeojsonCbsaData: (data: GeojsonData) => void;
  setSidepanelData: (data: ExploreData | null) => void;
  setDomainTab: (tab: DomainTab) => void;
  setMapMetric: (metric: Metric) => void;
  setActiveTab: (tab: Tab) => void;
  setExploreData: (data: ExploreData | null) => void;
  setConfig: (config: OverallScoringConfig) => void;
  setMapRef: (map: mapboxgl.Map | null) => void;
  setSelectedLevel: (level: GeographyLevel) => void;
  setMetricScores: (
    level: string,
    metric: string,
    scores: { [key: string]: number }
  ) => void;
  setMetricLoading: (level: string, metric: string) => void;
  setMetricConfig: (config: MetricConfig) => void;
  setGeojsonTractData: (data: Array<GeojsonFeature>) => void;
}

export type StoreState = _StoreState & _StoreActions;

export interface SubindicatorScoringConfig {
  level: "subindicator";
  name: Metric;
  weight: number;
  datapoint_key: string;
}

export type ScoringConfigTier =
  | "overall"
  | "domain"
  | "category"
  | "subcategory"
  | "indicator"
  | "subindicator";

export interface BaseScoringConfig {
  level: ScoringConfigTier;
  name: Metric;
  weight: number;
  description: string;
  long_description: string | null;
}

export interface SubindicatorScoringConfig extends BaseScoringConfig {
  level: "subindicator";
  datapointKey: string;
}

interface ParentScoringConfigBase extends BaseScoringConfig {
  items: BaseScoringConfig[];
}

interface IndicatorScoringConfig extends ParentScoringConfigBase {
  level: "indicator";
  items: SubindicatorScoringConfig[];
}

interface SubcategoryScoringConfig extends ParentScoringConfigBase {
  level: "subcategory";
  items: IndicatorScoringConfig[];
}

interface CategoryScoringConfig extends ParentScoringConfigBase {
  level: "category";
  items: SubcategoryScoringConfig[];
}

interface DomainScoringConfig extends ParentScoringConfigBase {
  level: "domain";
  items: CategoryScoringConfig[];
}

interface OverallScoringConfig extends ParentScoringConfigBase {
  level: "overall";
  items: DomainScoringConfig[];
}

export type ParentScoringConfig =
  | OverallScoringConfig
  | DomainScoringConfig
  | CategoryScoringConfig
  | SubcategoryScoringConfig
  | IndicatorScoringConfig;

export const TabValues = ["Explore", "Inspect", "Compare"];
export type Tab = (typeof TabValues)[number];

export const DomainTabValues = [
  "Overall",
  "Growth Potential",
  "Baseline Resilience",
  "Hazard Insulation",
  "Path to Sustainability",
] as const;

export interface LocationInput {
  id: string;
  level: GeographyLevel;
}

export type DomainTab = (typeof DomainTabValues)[number];

interface _CompareStoreState {
  locations: LocationInput[];
  categoryPath: string;
}

interface _CompareStoreActions {
  setLocations(locations: LocationInput[]): void;
  addLocation(location: LocationInput): void;
  removeLocation(index: number): void;
  setCategoryPath(path: string): void;
}

export const useCompareStore = create<
  _CompareStoreState & _CompareStoreActions
>((set) => ({
  locations: [],
  categoryPath: "",
  setLocations(locations: LocationInput[]): void {
    set({ locations });
  },
  addLocation(location: LocationInput): void {
    set((state) => {
      if (
        state.locations.find(
          ({ level, id }) => level === location.level && id === location.id
        )
      ) {
        return { locations: state.locations };
      } else {
        return { locations: [...state.locations, location] };
      }
    });
  },
  removeLocation(index: number): void {
    set((state) => ({
      locations: state.locations.filter((_, i) => i !== index),
    }));
  },
  setCategoryPath(path: string): void {
    set({ categoryPath: path });
  },
}));

// Helper to generate the URL
export function generateCompareUrl(
  categoryPath: string,
  locations: LocationInput[]
): string {
  const baseUrl = `/compare${categoryPath ? `/${categoryPath}` : ""}`;
  if (locations.length === 0) return baseUrl;

  const params = new URLSearchParams();
  locations.forEach((loc, index) => {
    params.append(`loc${index}`, `${loc.level}:${loc.id}`);
  });

  return `${baseUrl}?${params.toString()}`;
}

// Hook to sync store with URL
export function useCompareUrlSync() {
  const navigate = useNavigate();
  const { locations, categoryPath } = useCompareStore();

  useEffect(() => {
    const url = generateCompareUrl(categoryPath, locations);
    navigate(url, { replace: true });
  }, [locations, categoryPath, navigate]);
}

export const useStore = create<StoreState>((set) => ({
  authStatus: null,
  geojsonData: null,
  geojsonCbsaData: null,
  sidepanelData: null,
  domainTab: "Overall",
  mapMetric: "Overall",
  activeTab: "Explore",
  exploreData: null,
  config: null,
  mapRef: null,
  selectedLevel: "csa",
  metricScores: {},
  metricConfig: null,
  geojsonTractData: null,
  setAuthStatus: (data: AuthStatus) => set({ authStatus: data }),
  setGeojsonData: (data: GeojsonData) => set({ geojsonData: data }),
  setGeojsonCbsaData: (data: GeojsonData) => set({ geojsonCbsaData: data }),
  setSidepanelData: (data: ExploreData | null) => set({ sidepanelData: data }),
  setDomainTab: (tab: DomainTab) => set({ domainTab: tab }),
  setMapMetric: (metric: Metric) => set({ mapMetric: metric }),
  setActiveTab: (tab: Tab) => set({ activeTab: tab }),
  setExploreData: (data: ExploreData | null) => set({ exploreData: data }),
  setConfig: (config: OverallScoringConfig) => set({ config: config }),
  setMapRef: (map: mapboxgl.Map | null) => set({ mapRef: map }),
  setSelectedLevel: (level: GeographyLevel) => set({ selectedLevel: level }),
  setMetricScores: (
    level: string,
    metric: string,
    scores: { [key: string]: number }
  ) =>
    set((state) => ({
      metricScores: {
        ...state.metricScores,
        [`${level}:${metric}`]: {
          scores,
          loading: false,
        },
      },
    })),
  setMetricLoading: (level: string, metric: string) =>
    set((state) => ({
      metricScores: {
        ...state.metricScores,
        [`${level}:${metric}`]: {
          scores: {},
          loading: true,
        },
      },
    })),
  setMetricConfig: (config: MetricConfig) => set({ metricConfig: config }),
  setGeojsonTractData: (data: Array<GeojsonFeature>) =>
    set((state) => ({
      geojsonTractData: {
        ...state.geojsonTractData,
        ...Object.fromEntries(data.map((d) => [d.properties.tractCode, d])),
      },
    })),
  getConsolidatedTractData: (): GeojsonData | null => {
    const geojsonTractDataByTractCode =
      useStore.getState().geojsonTractData || [];
    const features = Object.values(geojsonTractDataByTractCode);
    if (features.length === 0) {
      return null;
    }
    return {
      type: "FeatureCollection",
      features: features,
    };
  },
}));

export const useMetricMapping = create<{
  mapping: string[];
  setMapping: (metrics: string[]) => void;
}>((set) => ({
  mapping: [],
  setMapping: (metrics: string[]) => set({ mapping: metrics }),
}));

export function getDatapointSource(metric: Metric): string | null {
  const metricConfig = useStore.getState().metricConfig;
  if (!metricConfig?.datapoints || !metricConfig?.sources) {
    return null;
  }
  const sourceId = metricConfig?.datapoints[metric];
  if (!sourceId) {
    return null;
  }
  return metricConfig?.sources[sourceId];
}

export function getScore(
  scores: number[] | undefined | null,
  metric: Metric
): number | null {
  if (metric.toLowerCase() === "overall") {
    metric = "overall";
  }
  const mapping = useMetricMapping.getState().mapping;
  const index = mapping.indexOf(metric);
  if (!scores || index === -1) {
    return null;
  }
  const rawScore = scores[index];
  return rawScore === null || rawScore === undefined ? null : rawScore;
}

export function getScoreTier(
  scoreTiers: number[] | undefined | null,
  metric: Metric
): number | null {
  const mapping = useMetricMapping.getState().mapping;
  const index = mapping.indexOf(metric);
  if (!scoreTiers) {
    return null;
  }
  return index === -1 ? 0 : scoreTiers[index];
}

export function useFlatConfig() {
  const config = useStore((state) => state.config);
  const memoized = useRef<{
    lastConfig: OverallScoringConfig | null;
    lastFlatConfig: { [key: string]: BaseScoringConfig };
  }>({
    lastConfig: null,
    lastFlatConfig: {},
  });

  const getBase = ({
    level,
    name,
    weight,
    description,
    long_description,
  }: BaseScoringConfig): BaseScoringConfig => ({
    level,
    name,
    weight,
    description,
    long_description,
  });

  if (config !== memoized.current.lastConfig) {
    memoized.current.lastConfig = config;
    if (!config) {
      memoized.current.lastFlatConfig = {};
    } else {
      const flatConfig: Array<BaseScoringConfig> = config.items.flatMap(
        (category) => [
          getBase(category),
          ...category.items.flatMap((subcategory) => [
            getBase(subcategory),
            ...subcategory.items.flatMap((indicator) => [
              getBase(indicator),
              ...indicator.items.map((subindicator) => getBase(subindicator)),
            ]),
          ]),
        ]
      );
      memoized.current.lastFlatConfig = Object.fromEntries(
        flatConfig.map((subcategory) => [subcategory.name, subcategory])
      );
    }
  }

  return memoized.current.lastFlatConfig;
}
