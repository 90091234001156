import React from "react";

const ScorePill = ({ label, color }: { label: string; color: string }) => (
  <span
    className={`px-2 py-1 text-white text-center`}
    style={{
      backgroundColor: color,
      width: 90,
      height: 25,
      fontFamily: "Barlow, sans-serif",
      fontWeight: "700",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {label}
  </span>
);

export default ScorePill;
