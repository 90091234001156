import React, { useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import WelcomePage from "./pages/WelcomePage";
import { useApiRequest } from "./network";
import { SnackbarProvider } from "notistack";
import { useStore } from "./store";

function Layout() {
  const { setAuthStatus, authStatus } = useStore((state) => ({
    setAuthStatus: state.setAuthStatus,
    authStatus: state.authStatus,
  }));
  const makeApiRequest = useApiRequest();

  useEffect(() => {
    const checkLoginStatus = async () => {
      await makeApiRequest({
        method: "GET",
        endpoint: "/user",
        onSuccess: setAuthStatus,
      });
    };
    checkLoginStatus();
  }, []);

  if (!authStatus) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavBar />
      <Outlet />;
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>404 Not Found</h2>
    </div>
  );
}

export default function App() {
  const { authStatus } = useStore((state) => ({
    authStatus: state.authStatus,
  }));
  const whitelisted = authStatus?.user?.whitelisted;

  return (
    <SnackbarProvider maxSnack={5}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              whitelisted ? <HomePage initialTab={null} /> : <WelcomePage />
            }
          />
          <Route
            path="compare/:categoryPath?"
            element={<HomePage initialTab={"Compare"} />}
          />
          <Route
            path="explore/:level/:id"
            element={<HomePage initialTab={"Inspect"} />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </SnackbarProvider>
  );
}
