import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  background: #f3f4f6;
  padding: 2px;
  border-radius: 8px;
  gap: 2px;
`;

const Option = styled.button<{ $isSelected: boolean }>`
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  background: ${(props) => (props.$isSelected ? "white" : "transparent")};
  color: ${(props) => (props.$isSelected ? "#3182ce" : "#4b5563")};
  box-shadow: ${(props) =>
    props.$isSelected ? "0 1px 3px rgba(0,0,0,0.1)" : "none"};

  &:hover {
    background: ${(props) => (props.$isSelected ? "white" : "#e5e7eb")};
  }
`;

interface SegmentedControlOption {
  value: string;
  label: string;
}

interface SegmentedControlProps {
  value: string;
  options: SegmentedControlOption[];
  onChange: (value: string) => void;
}

export const SegmentedControl: React.FC<SegmentedControlProps> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <Container>
      {options.map((option) => (
        <Option
          key={option.value}
          $isSelected={value === option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Option>
      ))}
    </Container>
  );
};
