import React, { useState } from "react";
import styled from "styled-components";
import { BaseScoringConfig, ParentScoringConfig, useStore } from "../store";
import Map from "./Map";
import { getCentroid } from "../utils";
import { DefaultScoreCard } from "./ScoreCard";
import { BodyTextLight, SmMediumTitle, SmallTitleLight } from "../text";
import { useFindFeatureFromExploreData } from "../hooks/useGeographyData";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IndicatorsSection = styled.div`
  width: 50%;
  padding-right: 20px;
`;

const MapAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
`;

const MapSection = styled.div`
  width: 100%;
  height: 400px;
  border: 1px dotted #ccc;
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

const BreadcrumbItem = styled(SmMediumTitle)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Separator = styled.span`
  color: #666;
  font-size: 14px;
  user-select: none;
`;

const Drilldown = ({
  initialCategoryPath,
}: {
  initialCategoryPath: string;
}) => {
  const { exploreData, config } = useStore((state) => ({
    exploreData: state.exploreData,
    config: state.config,
  }));
  const [categoryPath, setCategoryPath] = useState(initialCategoryPath);
  const ourFeature = useFindFeatureFromExploreData()(exploreData);

  if (!exploreData || !config) {
    return null;
  }
  const categoryParts = categoryPath.split(".");
  const getCategoryConfig = () => {
    let current: ParentScoringConfig = config as ParentScoringConfig;
    for (const part of categoryParts) {
      if (!current.items) return undefined;
      const found = (current.items as BaseScoringConfig[]).find(
        (item) => item.name === part
      );
      if (!found) return undefined;
      if (!("items" in found)) return undefined;
      current = found as ParentScoringConfig;
    }

    return current;
  };
  const categoryConfig = getCategoryConfig();
  if (!categoryConfig) {
    return;
  }
  const subcategories = categoryConfig.items;
  return (
    <Container>
      <Header>
        <Breadcrumb>
          {categoryParts.map((part, i) => (
            <React.Fragment key={part}>
              <BreadcrumbItem
                onClick={() =>
                  setCategoryPath(categoryParts.slice(0, i + 1).join("."))
                }
              >
                {part}
              </BreadcrumbItem>
              {i < categoryParts.length - 1 && <Separator>›</Separator>}
            </React.Fragment>
          ))}
        </Breadcrumb>
      </Header>
      <Content>
        <IndicatorsSection>
          <SmallTitleLight>{categoryConfig.description}</SmallTitleLight>
          <br />
          {subcategories.map((subconfig) => (
            <DefaultScoreCard
              key={subconfig.name}
              onClick={() =>
                setCategoryPath(`${categoryPath}.${subconfig.name}`)
              }
              config={subconfig}
              properties={exploreData.properties}
            />
          ))}
        </IndicatorsSection>
        <MapAndDescription>
          <MapSection>
            <Map
              customMapMetric={categoryConfig.name}
              customCenter={ourFeature && getCentroid(ourFeature)}
              zoom={5.5}
            />
          </MapSection>
          <div>
            <BodyTextLight>
              Shaded according to {categoryConfig.name} score
            </BodyTextLight>
          </div>
        </MapAndDescription>
      </Content>
    </Container>
  );
};

export default Drilldown;
