import { useCallback } from "react";
import { ExploreData, GeojsonFeature, useStore } from "../store";
import { GeographyLevel } from "../store";

export function useGeographyData(level: GeographyLevel | null | undefined) {
  const { geojsonData, geojsonCbsaData, geojsonTractData } = useStore(
    (state) => ({
      geojsonData: state.geojsonData,
      geojsonCbsaData: state.geojsonCbsaData,
      geojsonTractData: state.geojsonTractData,
    })
  );

  return level === "cbsa"
    ? geojsonCbsaData
    : level === "csa"
    ? geojsonData
    : level === "tract" && geojsonTractData
    ? {
        type: "FeatureCollection",
        features: Object.values(geojsonTractData),
      }
    : null;
}

export const getIdField = (
  level: GeographyLevel | null | undefined
): "csaCode" | "cbsaCode" | "tractCode" =>
  level === "cbsa"
    ? "cbsaCode"
    : level === "csa"
    ? "csaCode"
    : level === "tract"
    ? "tractCode"
    : "csaCode";

export const getIdFromExploreData = (
  exploreData: ExploreData | null
): string | null =>
  exploreData?.properties[getIdField(exploreData?.level)] || null;

export function useFindFeature() {
  const geojsonData = useGeographyData("csa");
  const geojsonCbsaData = useGeographyData("cbsa");
  const geojsonTractData = useGeographyData("tract");

  return useCallback(
    (
      id: string | null | undefined,
      level: GeographyLevel | null | undefined
    ): GeojsonFeature | null => {
      const geographyData =
        level === "cbsa"
          ? geojsonCbsaData
          : level === "csa"
          ? geojsonData
          : level === "tract"
          ? geojsonTractData
          : null;

      const idField = getIdField(level);

      if (!idField || !geographyData) {
        return null;
      }

      return (
        geographyData.features.find(
          (feature) => feature.properties[idField] === id
        ) || null
      );
    },
    [geojsonData, geojsonCbsaData, geojsonTractData]
  );
}

export function useFindFeatureFromExploreData() {
  const findFeature = useFindFeature();

  return useCallback(
    (exploreData: ExploreData | null) => {
      const id = getIdFromExploreData(exploreData);
      return findFeature(id, exploreData?.level);
    },
    [findFeature]
  );
}
