import { useEffect } from "react";
import { GeographyLevel, useStore } from "../store";
import { useApiRequest } from "../network";

export const useGetOrFetchScores = (
  level: GeographyLevel,
  metric: string
): { scores: { [key: string]: number } | null; loading: boolean } => {
  if (metric === "Overall") {
    metric = "overall";
  }
  const { metricScores, setMetricScores, setMetricLoading } = useStore(
    (state) => ({
      metricScores: state.metricScores,
      setMetricScores: state.setMetricScores,
      setMetricLoading: state.setMetricLoading,
    })
  );
  const makeApiRequest = useApiRequest();
  const key = `${level}:${metric}`;
  const currentScores = metricScores[key];

  useEffect(() => {
    const fetchScores = async () => {
      if (!currentScores && metric) {
        setMetricLoading(level, metric);

        await makeApiRequest({
          method: "GET",
          endpoint: `/scores?level=${level}&metric=${encodeURIComponent(
            metric
          )}`,
          hideErrorSnackbar: true,
          hideSuccessSnackbar: true,
          onSuccess: (data) => {
            setMetricScores(level, metric, data.scores);
          },
          onError: (error) => {
            console.error("Error fetching scores:", error);
          },
        });
      }
    };

    fetchScores();
  }, [level, metric]);

  return {
    scores: currentScores?.scores,
    loading: currentScores?.loading,
  };
};
