import React, { useEffect } from "react";
import styled from "styled-components";
import {
  BaseScoringConfig,
  ParentScoringConfig,
  useStore,
  useCompareStore,
  useCompareUrlSync,
  LocationInput,
  GeographyLevel,
} from "../store";
import { MediumTitle } from "../text";
import ComparisonTable from "./ComparisonTable";
import ComparePageSelection from "./ComparePageSelection";
import { useParams, useSearchParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const ComparisonPage = () => {
  const { categoryPath } = useParams<{ categoryPath?: string }>();
  const [searchParams] = useSearchParams();
  const { config } = useStore((state) => ({ config: state.config }));
  const { setLocations, setCategoryPath } = useCompareStore();

  // Sync URL parameters to store
  useEffect(() => {
    if (categoryPath) {
      setCategoryPath(categoryPath);
    }

    // Parse location parameters
    const locations: LocationInput[] = [];
    searchParams.forEach((value, key) => {
      if (key.startsWith("loc")) {
        const [level, id] = value.split(":");
        if (level && id) {
          locations.push({ level: level as GeographyLevel, id });
        }
      }
    });
    setLocations(locations);
  }, [categoryPath, searchParams, setCategoryPath, setLocations]);

  useCompareUrlSync();

  const getCategories = () => {
    if (!categoryPath) {
      return (config as ParentScoringConfig).items;
    }

    let current: ParentScoringConfig = config as ParentScoringConfig;
    for (const part of categoryPath.split(".")) {
      if (!current?.items) return [];
      const found = (current.items as BaseScoringConfig[]).find(
        (item: BaseScoringConfig) => item.name === part
      );
      if (!found || !("items" in found)) return [];
      current = found as ParentScoringConfig;
    }
    return current?.items as BaseScoringConfig[];
  };

  const categories = getCategories();

  return (
    <Container>
      <Header>
        <MediumTitle>Location Comparison</MediumTitle>
      </Header>
      {categories && <ComparisonTable subcategories={categories} />}
    </Container>
  );
};

export default ComparisonPage;
