import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getIdField,
  useFindFeature,
  useGeographyData,
} from "../hooks/useGeographyData";
import { GeographyLevel, useCompareStore, getScore } from "../store";
import { useDebounce } from "../hooks/useDebounce";
import { scoreColor } from "../utils";

const SelectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
`;

const SearchResults = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
`;

const SearchResult = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f8f9fa;
  }
  border-bottom: 1px solid #eee;
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  &:hover {
    color: #c82333;
  }
`;

const LocationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const LocationItem = styled.div<{ isDragging: boolean; color?: string }>`
  padding: 12px;
  background-color: ${(props) => props.color || "#e9ecef"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) =>
    props.isDragging ? "0 5px 10px rgba(0,0,0,0.15)" : "none"};
  color: white;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const DragHandle = styled.div`
  cursor: grab;
  padding: 0 8px;
  color: rgba(255, 255, 255, 0.7);

  &:hover {
    color: white;
  }
`;

const LocationInfo = styled.div`
  flex: 1;
`;

const LocationName = styled.div`
  font-weight: 500;
`;

const LocationId = styled.div`
  font-size: 0.8em;
  opacity: 0.8;
`;

export default function ComparePageSelection() {
  const findFeature = useFindFeature();
  const { locations, setLocations, addLocation, removeLocation, categoryPath } =
    useCompareStore();
  const [selectedLevel, setSelectedLevel] = useState<GeographyLevel>("csa");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const debouncedSearch = useDebounce(searchTerm, 300);
  const geographyData = useGeographyData(selectedLevel);

  const searchResults = useMemo(() => {
    if (!debouncedSearch || !geographyData) return [];

    const searchLower = debouncedSearch.toLowerCase();
    return geographyData.features
      .filter((feature) => {
        const name = feature.properties.name.toLowerCase();
        const id =
          feature.properties[
            selectedLevel === "csa"
              ? "csaCode"
              : selectedLevel === "cbsa"
              ? "cbsaCode"
              : "tractCode"
          ]?.toLowerCase();
        return name.includes(searchLower) || id?.includes(searchLower);
      })
      .slice(0, 10);
  }, [debouncedSearch, geographyData, selectedLevel]);

  const handleAddLocation = () => {
    if (selectedLocation) {
      addLocation({ id: selectedLocation.id, level: selectedLevel });
      setSelectedLocation(null);
      setSearchTerm("");
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(locations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocations(items);
  };

  const getLocationScore = (location: {
    id: string;
    level: GeographyLevel;
  }) => {
    const feature = findFeature(location.id, location.level);
    if (!feature || !categoryPath) return null;

    const pathParts = categoryPath.split(".");
    const lastCategory = pathParts[pathParts.length - 1];
    return getScore(feature.properties.scoreTiers, lastCategory);
  };

  return (
    <div>
      <SelectionPanel>
        <Select
          value={selectedLevel}
          onChange={(e) => {
            setSelectedLevel(e.target.value as GeographyLevel);
            setSelectedLocation(null);
            setSearchTerm("");
          }}
        >
          <option value="csa">CSA</option>
          <option value="cbsa">CBSA</option>
          <option value="tract">Tract</option>
        </Select>

        <Input
          placeholder="Search by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {searchResults.length > 0 && (
          <SearchResults>
            {searchResults.map((feature) => {
              const id = feature.properties[getIdField(selectedLevel)];
              return (
                <SearchResult
                  key={id}
                  onClick={() =>
                    id
                      ? setSelectedLocation({
                          id,
                          name: feature.properties.name,
                        })
                      : null
                  }
                >
                  {feature.properties.name} ({id})
                </SearchResult>
              );
            })}
          </SearchResults>
        )}

        {selectedLocation && (
          <Button onClick={handleAddLocation}>
            Add {selectedLocation.name}
          </Button>
        )}
      </SelectionPanel>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="locations">
          {(provided) => (
            <LocationsList {...provided.droppableProps} ref={provided.innerRef}>
              {locations.map((location, index) => {
                const feature = findFeature(location.id, location.level);
                const score = getLocationScore(location);
                return (
                  <Draggable
                    key={`${location.level}-${location.id}`}
                    draggableId={`${location.level}-${location.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <LocationItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                        color={score !== null ? scoreColor(score) : undefined}
                      >
                        <DragHandle {...provided.dragHandleProps}>⋮</DragHandle>
                        <LocationInfo>
                          <LocationName>
                            {feature?.properties?.name}
                          </LocationName>
                          <LocationId>
                            {location.level.toUpperCase()}: {location.id}
                          </LocationId>
                        </LocationInfo>
                        <RemoveButton onClick={() => removeLocation(index)}>
                          ×
                        </RemoveButton>
                      </LocationItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </LocationsList>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
