import mapboxgl, { LngLatLike, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useCallback, useRef } from "react";
import { getScore, Metric } from "../store";
import { getOrdinal, scoreColor } from "../utils";

interface ShowPopupProps {
  feature: MapboxGeoJSONFeature;
  mouseLngLat: LngLatLike;
  mapMetric: Metric;
  map: Map;
}

export const useMapboxPopup = () => {
  const popup = useRef<mapboxgl.Popup | null>(null);

  const initPopup = useCallback(() => {
    if (!popup.current)
      popup.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
  }, []);

  const addPopup = useCallback(
    ({ feature, mouseLngLat, mapMetric, map }: ShowPopupProps) => {
      const { properties } = feature;
      if (popup.current && properties) {
        const name = properties["name"];
        const scoreTiers = JSON.parse(properties["scoreTiers"]);
        const score = getScore(scoreTiers, mapMetric);
        const percentile = Math.round(score! * 100);

        const popupHTML = `
            <div class="popup-title">${name}</div>
            <div class="popup-subtitle">${mapMetric}</div>
            <div class="popup-row">
                <div class="row-number">
                    <div class="row-color" style="background-color:${scoreColor(
                      score
                    )};"></div>
                    <div class="row-percentile">${getOrdinal(percentile)}
                    </div>
                </div>
                <div class="row-after">Percentile in Nation</div>
            </div>
            `;

        popup.current
          .setLngLat(mouseLngLat)
          .setHTML(popupHTML)
          .setMaxWidth("640px")
          .addClassName("climate-map-popup");
        popup.current.addTo(map);
        popup.current.trackPointer();
      }
    },
    []
  );

  const removePopup = useCallback(() => {
    popup.current?.remove();
  }, []);

  return {
    initPopup,
    addPopup,
    removePopup,
  };
};
