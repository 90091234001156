import React from "react";
import styled from "styled-components";
import { getScore, useStore } from "../store";
import { DefaultScoreCard } from "./ScoreCard";
import { Title, BodyText, SmallTitleBold, SmallTitleLight } from "../text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  margin-top: 12px;
`;

const CategoryColumn = styled.div`
  flex: 1;
  width: 50%;
`;

const CoreDrivers = () => {
  const { sidepanelData, config } = useStore((state) => ({
    sidepanelData: state.sidepanelData,
    config: state.config,
  }));

  const subcategories =
    config?.items.flatMap((category) =>
      category.items.map((subcategory) => subcategory)
    ) || [];
  if (!sidepanelData) return null;

  const scoreTiers = sidepanelData.properties.scoreTiers;

  subcategories.sort((a, b) => {
    const aScore = (getScore(scoreTiers, a.name) || 0) * (a.weight || 1);
    const bScore = (getScore(scoreTiers, b.name) || 0) * (b.weight || 1);
    return aScore - bScore;
  });
  const topThreeCoreWeaknesses = subcategories.slice(0, 3);
  subcategories.sort((a, b) => {
    const aScore = (1 - (getScore(scoreTiers, a.name) || 0)) * (a.weight || 1);
    const bScore = (1 - (getScore(scoreTiers, b.name) || 0)) * (b.weight || 1);
    return aScore - bScore;
  });
  const topThreeCoreStrengths = subcategories.slice(0, 3);

  return (
    <Container>
      <Title>Core Drivers</Title>
      <SmallTitleLight>
        Indicators that have the greatest impact on the area's overall
        assessment
      </SmallTitleLight>
      <CategoriesContainer>
        <CategoryColumn>
          <SmallTitleBold>Key Strengths</SmallTitleBold>
          <BodyText style={{ marginBottom: "12px" }}>
            Area's comparative advantages in the changing climate
          </BodyText>
          {topThreeCoreStrengths.map((subcategory) => (
            <DefaultScoreCard
              key={subcategory.name}
              config={subcategory}
              properties={sidepanelData.properties}
            />
          ))}
        </CategoryColumn>
        <CategoryColumn>
          <SmallTitleBold>Key Vulnerabilities</SmallTitleBold>
          <BodyText style={{ marginBottom: "12px" }}>
            Area's comparative disadvantages in the changing climate
          </BodyText>
          {topThreeCoreWeaknesses.map((subcategory) => (
            <DefaultScoreCard
              key={subcategory.name}
              config={subcategory}
              properties={sidepanelData.properties}
            />
          ))}
        </CategoryColumn>
      </CategoriesContainer>
    </Container>
  );
};

export default CoreDrivers;
