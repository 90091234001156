import React, { useEffect, useRef, useCallback } from "react";
import {
  useStore,
  StoreState,
  Metric,
  useMetricMapping,
  GeographyLevel,
  GeojsonData,
  GeojsonFeature,
} from "../store";
import mapboxgl, { LngLatLike, MapLayerEventType } from "mapbox-gl";
import { scoreColor, getCentroid } from "../utils";
import "mapbox-gl/dist/mapbox-gl.css";
import styled from "styled-components";
import { isValidCenter } from "../utils";
import { useMapboxPopup } from "../hooks/useMapboxPopup";
import { useApiRequest } from "../network";
import { getIdField } from "../hooks/useGeographyData";
import { debounce } from "lodash";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUB_TOKEN || "";

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

interface MapProps {
  customCenter?: LngLatLike | null;
  zoom?: number;
  customMapMetric?: Metric | null;
}

type MapboxEventCuston = (
  | mapboxgl.MapLayerMouseEvent
  | mapboxgl.MapLayerTouchEvent
) &
  mapboxgl.EventData;
type MapEventHandler = (e: MapboxEventCuston) => void;

const Map: React.FC<MapProps> = ({
  customCenter = null,
  zoom = 3.7,
  customMapMetric = null,
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const hoveredStateId = useRef<number | null>(null);
  const {
    geojsonData,
    setActiveTab,
    mapMetric,
    setSidepanelData,
    geojsonCbsaData,
    setMapRef,
    selectedLevel,
    setGeojsonTractData,
    getConsolidatedTractData,
  } = useStore((state: StoreState) => ({
    setActiveTab: state.setActiveTab,
    geojsonData: state.geojsonData,
    geojsonCbsaData: state.geojsonCbsaData,
    mapMetric: state.mapMetric,
    setSidepanelData: state.setSidepanelData,
    setMapRef: state.setMapRef,
    selectedLevel: state.selectedLevel,
    setGeojsonTractData: state.setGeojsonTractData,
    getConsolidatedTractData: state.getConsolidatedTractData,
    geojsonTractData: state.geojsonTractData,
  }));
  const { initPopup, addPopup, removePopup } = useMapboxPopup();
  const effectiveMapMetric = customMapMetric || mapMetric;
  const consolidatedTractData = getConsolidatedTractData();
  const makeApiRequest = useApiRequest();
  const listenersByLayerThenEvent = useRef<
    Record<string, Record<keyof MapLayerEventType, MapEventHandler>>
  >({});
  console.log("Map render", {
    hasMapRef: !!mapRef.current,
    hasGeojsonData: !!geojsonData,
    hasGeojsonCbsaData: !!geojsonCbsaData,
    hasConsolidatedTractData: !!consolidatedTractData,
    selectedLevel,
  });
  const onFeatureClick = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const feature = e.features[0];
      const idField = getIdField(selectedLevel);
      const data =
        selectedLevel === "cbsa"
          ? geojsonCbsaData
          : selectedLevel === "csa"
          ? geojsonData
          : consolidatedTractData;
      const properties = data?.features.find(
        (g) => g.properties[idField] === feature.properties[idField]
      )?.properties;
      if (properties) {
        console.log("Selected", selectedLevel, properties?.[idField]);
      }
      setSidepanelData(
        properties ? { level: selectedLevel, properties } : null
      );
      setActiveTab("Inspect");
      if (mapRef.current) {
        removePopup();
        mapRef.current.getCanvas().style.cursor = "";
        mapRef.current.flyTo({
          center: getCentroid(feature),
          zoom: selectedLevel === "tract" ? 9 : 4.5,
          speed: 0.8,
          curve: 1,
        });
      }
    },
    [
      geojsonData,
      geojsonCbsaData,
      setSidepanelData,
      setActiveTab,
      mapRef,
      selectedLevel,
      consolidatedTractData,
    ]
  );

  const fetchTractsInView = useCallback(async () => {
    if (!mapRef.current) return;
    const zoom = mapRef.current.getZoom();
    console.log("Zoomed at", zoom);
    if (zoom < 7.5) {
      return;
    }

    const bounds = mapRef.current.getBounds();
    const params = new URLSearchParams({
      w: bounds.getWest().toString(),
      s: bounds.getSouth().toString(),
      e: bounds.getEast().toString(),
      n: bounds.getNorth().toString(),
      zoom: zoom.toString(),
    });

    console.log("Fetching tracts based on zoom...");
    makeApiRequest({
      method: "GET",
      endpoint: `/tracts/zoom?${params}`,
      onSuccess: (data: GeojsonFeature[]) => {
        setGeojsonTractData(data);
        console.log(
          "Successfully fetched",
          data.length,
          "tracts for",
          "mycbsa"
        );
      },
      hideSuccessSnackbar: true,
      hideErrorSnackbar: true,
    });
  }, [setGeojsonTractData]);

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current;
    const debouncedFetch = debounce(fetchTractsInView, 500);
    console.log("Adding map zoomend stuff");
    map.on("zoomend", debouncedFetch);
    map.on("moveend", debouncedFetch);

    return () => {
      map.off("zoomend", debouncedFetch);
      map.off("moveend", debouncedFetch);
    };
  }, [fetchTractsInView, !!mapRef.current]);

  const addInteractiveEvents = useCallback(
    (map: mapboxgl.Map, selectedLevel: GeographyLevel, mapMetric: Metric) => {
      ["csa", "cbsa", "tract"].forEach((objName) => {
        const layerId = `${objName}-shapes`;
        const sourceName = `${objName}s`;

        // Remove existing event listeners
        if (listenersByLayerThenEvent.current[objName]) {
          Object.entries(listenersByLayerThenEvent.current[objName]).forEach(
            ([event, listener]) => {
              map.off(
                event as keyof MapLayerEventType,
                layerId,
                listener as MapEventHandler
              );
            }
          );
        }
        // Add the new ones
        if (objName === selectedLevel) {
          initPopup();
          const addListener = (
            event: keyof MapLayerEventType,
            listener: MapEventHandler
          ) => {
            if (!listenersByLayerThenEvent.current[objName]) {
              listenersByLayerThenEvent.current[objName] = {} as Record<
                keyof MapLayerEventType,
                MapEventHandler
              >;
            }
            listenersByLayerThenEvent.current[objName][event] = listener;
            map.on(event, layerId, listener as MapEventHandler);
          };
          addListener("click", onFeatureClick as MapEventHandler);
          const onFeatureHover = (e: MapboxEventCuston) => {
            if (e.features && e.features.length > 0) {
              hoveredStateId.current =
                typeof e.features[0].id === "number" ? e.features[0].id : null;
              if (hoveredStateId.current !== null) {
                map.setFeatureState(
                  { source: sourceName, id: hoveredStateId.current },
                  { hover: true }
                );

                if (e.originalEvent) {
                  addPopup({
                    feature: e.features[0],
                    mouseLngLat: e.lngLat,
                    mapMetric,
                    map,
                  });
                }
              }
              map.getCanvas().style.cursor = "pointer";
            }
          };
          const onFeatureLeave = () => {
            if (hoveredStateId.current !== null) {
              map.setFeatureState(
                { source: sourceName, id: hoveredStateId.current },
                { hover: false }
              );
            }
            hoveredStateId.current = null;
            map.getCanvas().style.cursor = "";
            removePopup();
          };
          addListener("mousemove", onFeatureHover as MapEventHandler);
          addListener("mouseleave", onFeatureLeave);
        }
      });
    },
    [onFeatureClick]
  );

  const getFillColor = useCallback((metric: Metric): mapboxgl.Expression => {
    const mapping = useMetricMapping.getState().mapping;
    if (metric === "Overall") {
      metric = "overall";
    }
    const metricIndex = mapping.indexOf(metric);

    return [
      "case",
      ["==", ["at", metricIndex, ["get", "scoreTiers"]], null],
      "#d1cdcd",
      [
        "interpolate",
        ["linear"],
        ["to-number", ["at", metricIndex, ["get", "scoreTiers"]]],
        0,
        scoreColor(0),
        0.25,
        scoreColor(0.25),
        0.5,
        scoreColor(0.5),
        0.75,
        scoreColor(0.75),
        1,
        scoreColor(1),
      ],
    ];
  }, []);
  const selectedPaintProperty = useCallback(
    (selected: boolean) => [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      selected ? 0.9 : 0.2,
      selected ? 0.7 : 0.1,
    ],
    []
  );

  const updateLayerProperties = useCallback(
    (
      map: mapboxgl.Map,
      selectedLevel: GeographyLevel,
      effectiveMapMetric: Metric
    ) => {
      ["csa", "cbsa", "tract"].forEach((level) => {
        const layerId = `${level}-shapes`;
        // Only update if layer exists
        if (map.getLayer(layerId)) {
          // For tracts, always keep visible but adjust opacity based on selection
          const newVisibility =
            level === "tract"
              ? "visible"
              : level === selectedLevel
              ? "visible"
              : "none";

          const currentVisibility = map.getLayoutProperty(
            layerId,
            "visibility"
          );

          // Only update if visibility actually changes
          if (currentVisibility !== newVisibility) {
            map.setLayoutProperty(layerId, "visibility", newVisibility);
          }

          // Adjust opacity based on selection
          const isSelected = selectedLevel === level;
          const currentOpacity = map.getPaintProperty(layerId, "fill-opacity");
          const newOpacity =
            level === "tract" && !isSelected
              ? [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0.2, // Hover opacity for unselected tracts
                  0.1, // Normal opacity for unselected tracts
                ]
              : selectedPaintProperty(isSelected);

          if (JSON.stringify(currentOpacity) !== JSON.stringify(newOpacity)) {
            map.setPaintProperty(layerId, "fill-opacity", newOpacity);
          }

          // Update fill color
          const fillColor = getFillColor(effectiveMapMetric);
          const currentFillColor = map.getPaintProperty(layerId, "fill-color");
          if (JSON.stringify(currentFillColor) !== JSON.stringify(fillColor)) {
            map.setPaintProperty(layerId, "fill-color", fillColor);
          }
        }
      });
    },
    [selectedPaintProperty, getFillColor]
  );

  const addSourceAndLayerIfNotExist = useCallback(
    (
      map: mapboxgl.Map,
      sourceId: string,
      layerId: string,
      data: GeojsonData | null
    ) => {
      if (!data) {
        return;
      }

      const mapboxSourceData: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: data.features.map((feature, index) => ({
          ...feature,
          type: "Feature" as const,
          id: index,
        })),
      };

      try {
        const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
        if (!source) {
          console.log(`Adding ${sourceId} source`);
          map.addSource(sourceId, {
            type: "geojson",
            data: mapboxSourceData,
          });
        } else {
          // Get current data from the source
          const currentData = (source as any)._data;

          // Only update if data has changed
          if (
            JSON.stringify(currentData) !== JSON.stringify(mapboxSourceData)
          ) {
            console.log(`Updating ${sourceId} source with new data`);
            source.setData(mapboxSourceData);
          }
        }

        // Only add layer if it doesn't exist
        if (!map.getLayer(layerId)) {
          map.addLayer({
            id: layerId,
            type: "fill",
            source: sourceId,
            layout: {},
          });
        }
      } catch (error) {
        console.error(`Error updating ${sourceId}:`, error);
      }
    },
    []
  );

  const addLayersIfSourcesLoaded = useCallback(() => {
    const map = mapRef.current;
    if (!map) {
      console.log("Map not initialized");
      return;
    }

    if (!map.isStyleLoaded()) {
      console.log("Style not loaded yet");
      return;
    }
    addSourceAndLayerIfNotExist(map, "csas", "csa-shapes", geojsonData);
    addSourceAndLayerIfNotExist(map, "cbsas", "cbsa-shapes", geojsonCbsaData);
    addSourceAndLayerIfNotExist(
      map,
      "tracts",
      "tract-shapes",
      consolidatedTractData
    );

    // Always update visibility and paint properties
    updateLayerProperties(map, selectedLevel, effectiveMapMetric);
    addInteractiveEvents(map, selectedLevel, effectiveMapMetric);
  }, [
    selectedLevel,
    effectiveMapMetric,
    addSourceAndLayerIfNotExist,
    addInteractiveEvents,
    geojsonData,
    geojsonCbsaData,
    updateLayerProperties,
    consolidatedTractData,
  ]);

  // Add a ref to track if we've added layers
  const layersAddedRef = useRef(false);
  const retryAttemptsRef = useRef(0);
  const MAX_RETRIES = 5;

  // 1. Initialize map when the mapcontainer is mounted
  useEffect(() => {
    if (!mapContainerRef.current || mapRef.current) {
      console.log("Skipping map init:", {
        hasContainer: !!mapContainerRef.current,
        hasMapRef: !!mapRef.current,
      });
      return;
    }

    console.log("Creating new map instance");
    const center = isValidCenter(customCenter) ? customCenter : [-97.4, 38];
    try {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/light-v11",
        projection: "mercator" as any,
        center: center as LngLatLike,
        zoom,
      });

      console.log("Map instance created");

      map.on("style.load", () => {
        console.log("Map style loaded");
      });

      map.on("error", (e) => {
        console.error("Mapbox error:", e);
      });

      mapRef.current = map;
      setMapRef(map);
      console.log("Map ref set");

      map.addControl(new mapboxgl.NavigationControl(), "top-right");
    } catch (error) {
      console.error("Error creating map:", error);
    }
  }, [zoom, setMapRef, customCenter]);

  // 2. Add layers to the map when the style is loaded
  useEffect(() => {
    const map = mapRef.current;
    if (!map || (!geojsonData && !geojsonCbsaData)) {
      console.log("Missing map or data:", {
        hasMap: !!map,
        hasGeojsonData: !!geojsonData,
        hasGeojsonCbsaData: !!geojsonCbsaData,
        hasConsolidatedTractData: !!consolidatedTractData,
      });
      return;
    }

    const tryAddLayers = () => {
      if (layersAddedRef.current) {
        console.log("Layers already added");
        return;
      }

      if (!map.isStyleLoaded()) {
        if (retryAttemptsRef.current >= MAX_RETRIES) {
          console.error("Max retries reached for adding layers");
          return;
        }

        console.log(
          `Style not loaded, attempt ${
            retryAttemptsRef.current + 1
          }/${MAX_RETRIES}`
        );
        retryAttemptsRef.current++;
        setTimeout(tryAddLayers, 100 * retryAttemptsRef.current);
        return;
      }

      console.log("Adding layers to map");
      addLayersIfSourcesLoaded();
      layersAddedRef.current = true;
    };

    // Reset state when dependencies change
    layersAddedRef.current = false;
    retryAttemptsRef.current = 0;

    // Start the process
    tryAddLayers();

    // Cleanup
    return () => {
      retryAttemptsRef.current = MAX_RETRIES; // Stop any pending retries
    };
  }, [geojsonData, geojsonCbsaData, addLayersIfSourcesLoaded]);

  // Add a debug effect to log state changes
  useEffect(() => {
    console.log("Map state changed:", {
      hasMap: !!mapRef.current,
      styleLoaded: mapRef.current?.isStyleLoaded(),
      hasGeojsonData: !!geojsonData,
      hasGeojsonCbsaData: !!geojsonCbsaData,
      layersAdded: layersAddedRef.current,
      retryAttempts: retryAttemptsRef.current,
    });
  }, [geojsonData, geojsonCbsaData]);

  return <MapContainer ref={mapContainerRef} />;
};

export default Map;
